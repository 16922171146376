import { QuestionType } from '@webapp/common/resources/constants';
import { getApiClass } from '@webapp/survey-new/src/api/queries-api';
import css from "./question.css";

var replaceMinMax = function replaceMinMax(tpl, min, max) {
  return tpl.replace(/{min}/, String(min)).replace(/{max}/, String(max));
};

var replacePoints = function replacePoints(tpl, points) {
  return tpl.replace(/{points}/, String(points));
};

export var invalidMessageFactory = function invalidMessageFactory(_ref, question) {
  var amount = _ref.amount,
      distribution = _ref.distribution,
      matrixAmount = _ref.matrixAmount,
      numRange = _ref.numRange;
  var answeredCount = question.answeredCount,
      answersActors = question.answersActors,
      groups = question.groups,
      invalid = question.invalid,
      _question$params = question.params,
      distributeScore = _question$params.distributeScore,
      distributeSurplus = _question$params.distributeSurplus,
      max = _question$params.maxLength,
      min = _question$params.minLength,
      dropdownType = _question$params.dropdownType,
      type = question.type;
  var withMax = typeof max === 'number';
  var withMin = typeof min === 'number';

  switch (type) {
    case QuestionType.SELECT_FEW_IMAGE:
      {
        if (withMax && answeredCount > max || withMin && answeredCount < min) {
          return replaceMinMax(amount, min, max);
        }

        return null;
      }

    case QuestionType.DISTRIBUTE_SCALE:
      {
        var pointsSum = answersActors.reduce(function (acc, item) {
          var response = item.getSnapshot().context.response;
          var value = response.getSnapshot().context.value;
          return acc + value;
        }, 0);
        var points = parseInt("".concat(distributeScore));

        if (pointsSum !== points && invalid && distributeSurplus) {
          return replacePoints(distribution, points);
        }

        return null;
      }

    case QuestionType.MATRIX_SINGLE_ANSWER:
      {
        var answersNumber = groups.reduce(function (acc, group) {
          return acc + Object.keys(group.responses).reduce(function (acc, key) {
            return acc + (group.responses.get(Number(key)).value ? 1 : 0);
          }, 0);
        }, 0);

        if (withMin && answersNumber < min || withMax && answersNumber > max) {
          return replaceMinMax(matrixAmount, min, max);
        }

        return null;
      }

    case QuestionType.TEST_FEW_OF_LIST:
    case QuestionType.FEW_OF_LIST:
    case QuestionType.DROPDOWN_LIST:
      {
        if (type === QuestionType.DROPDOWN_LIST && dropdownType !== 'multi') {
          return null;
        }

        if (withMin && answersActors.every(function (aActor) {
          return aActor.getSnapshot().context.answeredCount >= min;
        }) || withMax && answersActors.every(function (aActor) {
          return aActor.getSnapshot().context.answeredCount <= max;
        })) {
          return replaceMinMax(amount, min, max);
        }

        return null;
      }

    case QuestionType.MATRIX_FEW_ANSWERS:
      {
        if (withMin && answersActors.every(function (a) {
          return a.getSnapshot().context.answeredCount >= min;
        }) || withMax && answersActors.every(function (a) {
          return a.getSnapshot().context.answeredCount <= max;
        })) {
          return replaceMinMax(matrixAmount, min, max);
        }

        return null;
      }

    case QuestionType.NUMBER:
      {
        var api = getApiClass(QuestionType.NUMBER);

        if (!api.validate(question) && (withMin || withMax)) {
          return replaceMinMax(numRange, min, max);
        }

        return null;
      }

    default:
      return null;
  }
};
export var styleToQuestionPosition = {
  right: css.end,
  left: css.start,
  center: css.center
};