import _toConsumableArray from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
export var withAuth = function withAuth(ctx) {
  var _ctx$info$params$main = ctx.info.params.main,
      individualPasswords = _ctx$info$params$main.individualPasswords,
      passwordAccess = _ctx$info$params$main.passwordAccess,
      vkAuth = _ctx$info$params$main.vkAuth;
  return individualPasswords || passwordAccess || vkAuth;
};
export var withSurveyTimer = function withSurveyTimer(ctx, bundles, currentBundleIndex) {
  var _ctx$info$params$main2 = ctx.info.params.main,
      timer = _ctx$info$params$main2.timer,
      timerOn = _ctx$info$params$main2.timerOn;

  if (timerOn && bundles) {
    var allPrevBundlesQuestions = bundles.filter(function (_, idx) {
      return idx <= currentBundleIndex;
    }).reduce(function (acc, b) {
      return [].concat(_toConsumableArray(acc), _toConsumableArray(b.questions));
    }, []);

    if (allPrevBundlesQuestions.length > 0) {
      var isStartTimer = allPrevBundlesQuestions.find(function (q) {
        return String(timerOn) === String(q.getSnapshot().context.id);
      });

      if (isStartTimer) {
        return true;
      }
    }
  }

  return timer && !timerOn;
};
export var withRandomPages = function withRandomPages(pages) {
  return pages.some(function (p) {
    return p.random;
  });
};