import cn from 'classnames';
import { forwardRef } from 'react';

import { mergeRefs } from '@webapp/common/lib/react';
import { Scrollarea } from '@webapp/ui/lib/scrollarea';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';

import type { TPreset } from '../../select';
import { stopEvent } from '../lib';

import css from './options.css';

const saClass = { vertical: cn(css.scrollbar, css.vertical) };

export const Options = forwardRef<
    HTMLDivElement,
    {
        style: CSSProperties;
        dropdownStyle: CSSProperties;
        thumbColor: string;
        active: boolean;
        top: boolean;
        error: boolean;
        small: boolean;
        maxWidth?: boolean;
        children: JSX.Element | string | number | Array<JSX.Element | string | number>;
        title: JSX.Element | string;
        downshiftProps: any;
        searchable: boolean;
        mobile: boolean;
        preset?: TPreset;
    }
>(
    (
        {
            active,
            children,
            downshiftProps,
            dropdownStyle,
            error,
            maxWidth,
            mobile,
            searchable,
            small,
            style,
            thumbColor,
            title,
            top,
            preset
        },
        ref
    ) => {
        return (
            <div
                style={dropdownStyle}
                className={cn(CssUiComponent.SELECT_OPTIONS, css.dropdown, css[`dropdown_preset_${preset}`], {
                    [css.top]: top,
                    [css.active]: active,
                    [css.searchable]: searchable,
                    [css.maxWidth]: maxWidth,
                    [css.small]: small,
                    [css.error]: error
                })}
                {...downshiftProps}
                ref={mergeRefs([downshiftProps.ref, ref])}
            >
                {mobile && searchable && (
                    <div className={css.mobileSearch} style={style}>
                        {title}
                    </div>
                )}
                <div
                    style={style}
                    className={cn(css.optionsWrap, {
                        [css.top]: top,
                        [css.active]: active,
                        [css.error]: error
                    })}
                    onClick={stopEvent}
                >
                    <Scrollarea
                        className={css.scrollarea}
                        contentClassName={css.options}
                        horizontal={false}
                        scrollbarClassName={saClass}
                        scrollBarOffsetBottom={top ? (mobile ? 20 : 0) : 20}
                        scrollBarOffsetTop={top ? 20 : mobile ? 20 : 0}
                        stopScroll={true}
                        thumbColor={thumbColor}
                        vertical={true}
                    >
                        {children}
                    </Scrollarea>
                </div>
            </div>
        );
    }
);
Options.displayName = 'Options';
