import type { Dayjs } from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import cloneDeep from 'lodash/cloneDeep';

import type { BundleEntity } from '@webapp/survey-new/src/entities/bundle';
import type { AudioMachineDefinitionRef } from '@webapp/survey-new/src/machine/audio-machine';
import type { QuotaActorRef } from '@webapp/survey-new/src/machine/quota-machine';
import type { TimerMachineActor } from '@webapp/survey-new/src/machine/timer-machine';
import type { SmilePackName } from '@webapp/ui/lib/smile-image';

import type { CheckPasswordStatus } from '../lib/api';
import type { DateTimeType, TimeFormat } from '../lib/date';
import { getColor } from '../lib/ui';

import type {
    AnswerType,
    LogicBoolFunc,
    LogicTransition,
    LogicTransitionType,
    LogicType,
    ResidenceType
} from './constants';
import { DropdownType, TestCheckStatus } from './constants';
import type { InfoModel } from './entities/info';
import { initialInfo } from './entities/info';
import type { QuestionActorRef } from './entities/question-machine';
import { DropdownListType, SurveyStatus } from './survey';

export type NumBool = 0 | 1;

export interface TokenAuthSettings {
    password: NumBool | null;
    vkAuth: NumBool | null;
}

export interface TokenData {
    private: NumBool | TokenAuthSettings | null;
    license: NumBool | null;
    rid: string | number | null;
    survey: number | null;
}

export interface PageModel {
    id: number;
    title: string;
    onebyone: boolean;
    random: boolean;
    shuffleOrder: Array<number>;
}

export const initialPage: Readonly<PageModel> = {
    id: -1,
    title: '',
    onebyone: false,
    random: false,
    shuffleOrder: []
};

export interface ResidenceModel {
    id: number;
    name: string;
    level: number;
    parent: number | null;
}

export const initialResidence: Readonly<ResidenceModel> = {
    id: 0,
    name: '',
    level: 1,
    parent: null
};

export enum ImageTextPosition {
    UNDER = 'under',
    ABOVE = 'above'
}

export enum FileUploadType {
    ANY = 'any',
    RESTRICTED = 'restricted'
}

export interface DateFormat {
    label: string;
    format: string;
}

export interface QuestionParamsModel {
    name: boolean; // show name. TODO ?? purge
    required: boolean | null;
    randomOrder: boolean | null;
    excludeSelectedAnswer: boolean | null;

    timer: boolean;
    hours: number;
    minutes: number;
    seconds: number;

    help: boolean | null;
    helpText: string | null;

    comment: boolean | null;
    commentText: string | null;
    commentRequired: boolean | null;

    textBlock: string | null;

    agreementLeftLabel: string | null;
    agreementRightLabel: string | null;
    agreementLink: string | null;
    agreementText: string | null;

    dateType: DateTimeType | null;
    dateFormat: DateFormat | null;
    timeFormat: TimeFormat | null;

    minLength: number | null;
    maxLength: number | null;

    phoneFormatCountry: string | null;
    phoneFormatNumber: string | null;

    fileAmount: number | null;
    uploadButtonName: string | null;
    fileLimitExt: Array<string> | null;
    fileType: FileUploadType;

    residence: ResidenceType;

    answerType: 'any' | 'number' | null;

    size: number | null;
    amount: number | null;
    color: string | null;

    npsLeftLabel: string | null;
    npsRightLabel: string | null;
    npsColors: Array<string> | null;

    distributeSurplus: boolean | null;
    distributeLabel: string | null;
    distributeScore: number | null;
    distributeUnit: string | null;

    dropdownListType: DropdownType;
    dropdownType: DropdownListType;

    answers: Array<string>;

    scaleType: 'number' | 'custom';
    scaleStartDia: number;
    scaleEndDia: number;
    scaleFormat: 'numbers' | 'numbers-with-borders' | 'slider';
    scaleColors: Array<string>;

    imageColumns: number | null;
    questionColumns: number | null;
    imageTextPosition: ImageTextPosition | null;

    smileType: string | null;
    smilePack: SmilePackName | null;
    cantRate: boolean;
    cantRateText: string | null;
    matrixType: 'stars' | 'numbers';
    matrixNumbersColor: Array<string>;
    minAmount: number;
    maxAmount: number;
    csiFormat: string;
}

export const initialQuestionParams: Readonly<QuestionParamsModel> = {
    name: true,
    required: true,
    randomOrder: false,
    excludeSelectedAnswer: false,

    timer: false,
    hours: 0,
    minutes: 0,
    seconds: 0,

    help: false,
    helpText: null,

    comment: false,
    commentText: '',
    commentRequired: false,

    textBlock: null,

    agreementLeftLabel: null,
    agreementRightLabel: null,
    agreementLink: null,
    agreementText: null,

    dateType: null,
    dateFormat: null,
    timeFormat: null,

    minLength: null,
    maxLength: null,

    fileAmount: null,
    uploadButtonName: null,
    fileType: null,
    fileLimitExt: [],

    phoneFormatCountry: null,
    phoneFormatNumber: null,

    residence: null,

    answerType: null,

    size: null,
    amount: null,
    color: null,

    npsLeftLabel: null,
    npsRightLabel: null,
    npsColors: [getColor('blue-nps')],

    scaleType: 'number',
    scaleStartDia: 1,
    scaleEndDia: 5,
    scaleFormat: 'numbers',
    scaleColors: [getColor('blue-nps')],

    distributeSurplus: null,
    distributeLabel: null,
    distributeScore: null,
    distributeUnit: null,

    dropdownListType: DropdownType.DIFFERENT,
    dropdownType: DropdownListType.SINGLE,

    answers: [],

    imageColumns: 1,
    questionColumns: 1,
    imageTextPosition: ImageTextPosition.UNDER,

    smileType: null,
    smilePack: null,

    cantRateText: 'Не могу оценить',
    cantRate: false,
    minAmount: 1,
    maxAmount: 10,
    csiFormat: 'numbers',
    matrixType: 'stars',
    matrixNumbersColor: [getColor('blue-nps')]
};

export interface QuestionResponseModel {
    value: boolean | string | number | null;
    extra: boolean | string | number | null;
    comment: string | null;
}

export const initialQuestionResponse: Readonly<QuestionResponseModel> = {
    value: null,
    extra: null,
    comment: null
};

export interface LogicParamModel {
    id: number;
    checked: boolean | null;
}

export interface QuestionLogicModel {
    id: number;
    type: LogicType;
    action: string | null;
    transition: LogicTransition | null;
    transitionType: LogicTransitionType | null;
    boolFunc: LogicBoolFunc | null;
    toQuestion: number | null;
    toSurvey: number | null;
    toSurveyUrl: string | null;
    toPage: number | null;
    toWebsite: string | null;
    completeText: string | null;
    disqualText: string | null;
    params: Array<LogicParamModel>; // [])
    toPages: Array<number>;
    toQuestions: Array<number>;
    linkId: number | null;
    linkValue: string | null;
}

export interface QuestionFileResponse {
    id: number | null;
    path: string | null;
    name: string | null;
    width: number | null;
    height: number | null;
    mimeType: string | null;
}

export interface QuestionAnswerModel {
    id: number;
    value: Array<string>;
    type: AnswerType | null;
    exception: boolean;
    testCheckStatus: TestCheckStatus;
    file: QuestionFileResponse | null;
    valid: boolean;
    answeredCount: number;
}

export const initialQuestionAnswer: Readonly<QuestionAnswerModel> = {
    id: -1,
    value: [],
    type: null,
    exception: false,
    testCheckStatus: TestCheckStatus.NOT_CHECKED,
    file: null,
    valid: true,
    answeredCount: 0
};

export interface QuestionGroupModel {
    id: number;
    name: string | null;
    type: number | null;
    numericValue: number | null;
    exception: boolean;
    answers: Array<string>;
}

export const initialQuestionGroup: Readonly<QuestionGroupModel> = {
    id: -1,
    name: null,
    type: null,
    numericValue: null,
    exception: false,
    answers: []
};

export interface QuestionModel {
    id: number;
    title: string;
    page: number;
    type: number;
    order: number | null;
    params: QuestionParamsModel;
    answeredCount: number;
    logics: Array<QuestionLogicModel>;
    dirty: boolean;
    expired: boolean;
    errorText: string | null;
    valid: boolean; // question valid
    invalid: boolean; // answer invalid
    commentInvalid: boolean; // comment invalid
    timerTime: number | null; // TODO use duration
    timerId: number | null;
    isTestCheckedQuestion: boolean | TestCheckStatus;
    maxPoints: number | null;
    currentPoints: number | null;
    targetTime: Duration;
    factTime: Duration;
    shownByLogic: boolean;
    questionColumns: number;
}

export const initialQuestionModel: Readonly<QuestionModel> = {
    id: null,
    title: '',
    page: null,
    type: null,
    order: null,
    params: cloneDeep(initialQuestionParams),
    answeredCount: 0,
    logics: [],
    valid: true,
    invalid: false,
    commentInvalid: false,
    dirty: false,
    expired: false,
    errorText: null,
    timerTime: null, // TODO use duration
    timerId: null,
    isTestCheckedQuestion: false,
    maxPoints: null,
    currentPoints: null,
    targetTime: null,
    factTime: null,
    shownByLogic: false,
    questionColumns: 1
};

export enum BundleType {
    REDIRECT_URL = 'redirect_url',
    REDIRECT_SURVEY = 'redirect_survey',
    TEXT = 'text',
    QUESTIONS = 'questions'
}

export enum BundleFinishType {
    DISQUAL = 'disqual',
    STOP = 'stop',
    STOP_BY_TIMER = 'stopByTimer',
    TEXT = 'TEXT'
}

// export interface BundleModel {
//     type: BundleType | null;
//     finishType: BundleFinishType | null;
//     random: boolean;
//     text: string | null;
//     page: PageModel;
//     redirect: string | number | null;
// }

export interface TestScoreAnswer {
    correct: boolean;
    answered: boolean;
}
export interface TestScoreQuestion {
    maxScore: number;
    answers: Record<string, TestScoreAnswer>;
    score: number;
    answered: boolean;
}
export interface TestScore {
    maxScore: number;
    score: number;
    percent: number;
    questions: Record<number, TestScoreQuestion>;
}

export const initialTestScore: Readonly<TestScore> = {
    maxScore: 0,
    score: 0,
    percent: 0,
    questions: {}
};

export interface SurveyStore {
    agreementText: string | null;
    domain: string | null;
    token: string | null;
    tokenData: TokenData | null;
    testScore: TestScore | null;
    lastQuotasGetDate: Date | null;
    promocode: string;
    status: SurveyStatus;

    info: InfoModel;
    pages: Array<PageModel>;
    residence: Array<ResidenceModel>;
    questions: Array<QuestionActorRef>;
    bundles: Array<BundleEntity>;
    globalLogic: Array<QuestionLogicModel>;
    queryParams: Record<string, string>;
    linkParams: Record<string, string>;

    startDatetime: Dayjs;
    stopDatetime: Dayjs;

    loading: {
        init: boolean;
        load: boolean;
        fetch: boolean;
        next: boolean;
        stop: boolean;
        finish: boolean;
    };

    withRandom: boolean;

    // state
    currentBundleIndex: number;
    factTime: Duration;
    targetTime: Duration;
    factPoints: number | null;
    individualTries: number | null;
    progress: number;
    invalidQuestionsQuantity: number;

    scrollToQuestionId: number | null;

    /* new flags */
    auth: boolean;
    timerRef: TimerMachineActor;
    endDatetime: any;
    stopReason: BundleFinishType;
    pwdCheck: CheckPasswordStatus;
    audioRef: AudioMachineDefinitionRef;
    quotaRef: QuotaActorRef;
}

export const initialSurveyStore: Readonly<SurveyStore> = {
    agreementText: null,
    domain: null,
    token: null,
    tokenData: null,
    promocode: '',

    info: cloneDeep(initialInfo),
    pages: [],
    residence: [],
    questions: [],
    bundles: [],
    globalLogic: [],
    linkParams: {},
    queryParams: {},

    startDatetime: null,
    stopDatetime: null,
    lastQuotasGetDate: null,

    loading: {
        init: true,
        load: true,
        fetch: true,
        next: false,
        stop: false,
        finish: false
    },

    withRandom: false,

    factTime: null,
    targetTime: null,
    factPoints: null,

    currentBundleIndex: 0,
    individualTries: null,
    scrollToQuestionId: null,

    auth: false,
    pwdCheck: null,
    timerRef: null,
    status: SurveyStatus.STATUS_JUST_START,
    endDatetime: null,
    stopReason: null,
    audioRef: null,
    quotaRef: null,
    progress: 0,
    invalidQuestionsQuantity: 0,
    testScore: null
};
