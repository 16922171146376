import cn from 'classnames';

import { InlineCloseBtn } from '@webapp/ui/lib/close-btn';

import css from './tag.css';

interface TagProps {
    onClose?: () => void;
}

export const Tag: FC<TagProps> = ({ children, onClose }) => {
    return (
        <div className={cn(css.tag, 'multi-select-tag')}>
            <div className={css.tagText}>{children}</div>
            <InlineCloseBtn
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};
