import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import { nop } from '@webapp/common/lib/utils';
import { Search as SearchIcon } from '@webapp/ui/lib/icons';

import { CustomStylesCtx } from '../custom-styles';
import { CssUiComponent } from '../survey-custom';
import type { TextFieldProps } from '../textfield';

import { disableScroll, enableScroll, getLabel, getOptionText, getValue, onTop, preventDefault } from './lib';
import type { SelectOption } from './lib';
import { Option, Options } from './options';
import { Overlay } from './overlay';
import { Portal } from './portal';
import { SelectBox } from './select-box';
import { Tag } from './tag';
import { Tags } from './tags';
import { useDownshift } from './use-downshift';
import { usePopper } from './use-popper';

import css from './multi-select.css';

export type TPreset = 'light' | 'default';
export interface SelectOptions<T = SelectOption> {
    id?: string;
    className?: string;
    labelClassName?: string;
    size?: 'small' | 'default';
    min?: number;
    max?: number;
    value?: Array<PrimitiveValue>;
    defaultValue?: Array<string | number>;
    options?: Array<T>;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean | string;
    style?: CSSProperties;
    itemStyle?: CSSProperties;
    activeItemStyle?: CSSProperties;
    arrowStyle?: CSSProperties;
    controlClassName?: string;
    searchable?: boolean;
    fullWidth?: boolean;
    maxWidth?: boolean;
    renderLabel?: (label: string) => ReactNode;
    preset?: TPreset;

    onChange?(values: Array<PrimitiveValue>, options?: Array<T>): void;
}

export const MultiSelect: FC<SelectOptions> = memo(
    ({
        className,
        disabled,
        error,
        id,
        labelClassName,
        size,
        fullWidth,
        maxWidth,
        placeholder = 'Выбрать из списка',
        options = [],
        searchable,
        onChange = nop,
        defaultValue,
        value,
        renderLabel,
        preset,
        max = Infinity
    }) => {
        const isMobile = useMobile();
        const [innerValues, setInnerValues] = useState<Array<PrimitiveValue>>([]);
        const [selectedOptions, setSelectedOptions] = useState<Array<SelectOption>>([]);
        const [search, setSearch] = useState<string>('');
        const { select, text } = useContext(CustomStylesCtx);
        const [innerOptions, setInnerOptions] = useState<Array<SelectOption>>(options);
        const visibleValue =
            renderLabel && selectedOptions
                ? selectedOptions.map((o) => <Tag onClose={() => handleChange(o)}>{renderLabel(getLabel(o))}</Tag>)
                : selectedOptions.map((o) => <Tag onClose={() => handleChange(o)}>{getLabel(o)}</Tag>);

        const customStyles = useMemo(() => {
            if (fullWidth) {
                return {
                    ...(select || {}),
                    root: {
                        ...(select?.root || {}),
                        minWidth: null,
                        maxWidth: null,
                        width: null
                    }
                };
            }

            return select;
        }, [fullWidth, select]);

        const {
            item: { borderColor },
            root
        } = customStyles;
        const withError = !!error;
        const small = size === 'small' || customStyles.size === 'small';
        const empty = !Array.isArray(options) || options.length === 0;
        const rootRef = useRef(null);

        const [referenceRef, setReferenceRef] = useState(null);
        const [popperRef, setPopperRef] = useState(null);
        const { popperOptions, state, styles } = usePopper(referenceRef, popperRef, maxWidth);
        const top = onTop(state?.placement || popperOptions.placement);

        const getFilteredBySearch = useCallback(
            (search): Array<SelectOption> => {
                search = String(search || '').toLocaleLowerCase();

                if (!(searchable && search)) {
                    return options;
                }

                return options.filter((option) => getOptionText(option)?.toLocaleLowerCase()?.includes(search));
            },
            [options, searchable]
        );

        // TODO purge
        const isValueSelected = useCallback(
            (o: SelectOption): boolean => innerValues.includes(getValue(o)),
            [innerValues]
        );
        const findSelected = useCallback(
            (v: Array<PrimitiveValue>): Array<SelectOption> => {
                return options.filter((o) => v.includes(getValue(o)));
            },
            [options]
        );

        const updateSearch = useCallback(
            (v) => {
                setInnerOptions(getFilteredBySearch(v));
                setSearch(v);
            },
            [getFilteredBySearch]
        );

        const { closeMenu, getInputProps, getMenuProps, getToggleButtonProps, isOpen, openMenu } = useDownshift(
            innerOptions,
            selectedOptions,
            setSelectedOptions,
            updateSearch
        );

        const hideDropdown = useCallback((): void => {
            closeMenu();
            enableScroll();
            if (searchable) {
                updateSearch('');
            }
        }, [closeMenu, searchable, updateSearch]);

        const handleToggle = useCallback((): boolean | void => {
            if (empty || (disabled && !isOpen)) {
                return false;
            }
            if (isOpen) {
                disableScroll();
            } else {
                enableScroll();
            }
            if (searchable || !isOpen) {
                openMenu();
            } else {
                hideDropdown();
            }
        }, [empty, disabled, isOpen, searchable, openMenu, hideDropdown]);

        const inputProps = getInputProps({ placeholder }, { suppressRefError: true });
        const menuProps = getMenuProps({}, { suppressRefError: true });

        const createTitle = (): ReactNode => {
            let inputValue = search;
            inputValue = inputValue === null ? '' : inputValue;

            // form forces autocomplete disable
            return (
                <div
                    className={cn(
                        css.tagsWithSearch,
                        isMobile && css.tagsWithSearch_mobile,
                        isMobile && 'tagsWithSearch_mobile'
                    )}
                >
                    {visibleValue.length > 0 && (
                        <Tags>
                            {visibleValue}
                            {searchable && !isMobile && (
                                <form autoComplete='off' onSubmit={preventDefault}>
                                    <input
                                        className={css.searchInput}
                                        placeholder={placeholder}
                                        {...inputProps}
                                        autoComplete='off'
                                        value={inputValue}
                                    />
                                </form>
                            )}
                        </Tags>
                    )}
                </div>
            );
        };

        const clear = useCallback(
            (e) => {
                updateSearch('');
                setInnerValues([]);
                setSelectedOptions([]);
                onChange([], []);
                e.stopPropagation();
            },
            [onChange, updateSearch]
        );

        const createMobileSearch = useCallback((): ReactElement => {
            const props: TextFieldProps = { ...inputProps };
            props.onClick = preventDefault;
            props.onChange = (e) => {
                e.persist();
                inputProps.onChange(e); // TODO fix action logger
            };

            return (
                <form
                    autoComplete='off'
                    onClick={(e) => {
                        (e.target as HTMLElement).querySelector('input')?.focus();
                    }}
                    onSubmit={preventDefault}
                    className={css.mobileSearchForm}
                >
                    <SearchIcon className={css.mobileSearchIcon} />
                    <div className={cn(css.tagsWithSearch, css.tagsWithSearch_mobile, 'tagsWithSearch_mobile')}>
                        <Tags>
                            {visibleValue}
                            <input
                                className={css.searchInput}
                                placeholder={placeholder}
                                {...props}
                                autoComplete='off'
                            />
                        </Tags>
                    </div>
                </form>
            );
        }, [clear, closeMenu, inputProps, placeholder]);

        const handleChange = useCallback(
            (option: SelectOption): void => {
                const value = getValue(option);

                if (innerValues.includes(value)) {
                    setInnerValues((prev) => prev.filter((v) => v !== value));
                    setSelectedOptions((prev) => prev.filter((o) => getValue(o) !== value));
                    onChange(
                        innerValues.filter((v) => v !== value),
                        selectedOptions.filter((o) => getValue(o) !== value)
                    );
                } else {
                    if (innerValues.length + 1 > max) return;
                    setInnerValues((prev) => prev.concat(value));
                    setSelectedOptions((prev) => prev.concat(option));
                    onChange(innerValues.concat(value), selectedOptions.concat(option));
                }
            },
            [hideDropdown, selectedOptions, innerValues, onChange]
        );

        const optionsOverlay = useMemo(
            () => (
                <>
                    <Overlay style={customStyles.dropdownBg} onClick={hideDropdown} />
                    <Options
                        active={isOpen}
                        downshiftProps={menuProps}
                        dropdownStyle={{ ...styles.popper, minWidth: styles.popper.width }}
                        error={withError}
                        maxWidth={maxWidth}
                        mobile={isMobile}
                        ref={setPopperRef}
                        searchable={searchable}
                        small={small}
                        style={root}
                        thumbColor={borderColor}
                        title={isMobile && searchable && createMobileSearch()}
                        top={top}
                        preset={preset}
                    >
                        {innerOptions.map((option, idx) => (
                            <Option
                                key={idx}
                                option={option}
                                selected={isValueSelected(option) /* || highlightedIndex t=== idx*/}
                                small={small}
                                top={top}
                                onClick={handleChange}
                                renderLabel={renderLabel}
                            />
                        ))}
                    </Options>
                </>
            ),
            [
                borderColor,
                createMobileSearch,
                customStyles.dropdownBg,
                handleChange,
                hideDropdown,
                innerOptions,
                isMobile,
                isOpen,
                isValueSelected,
                maxWidth,
                menuProps,
                root,
                searchable,
                small,
                styles.popper,
                top,
                withError
            ]
        );

        useEffect(() => {
            setInnerValues((prev) => {
                let next = prev;
                if (typeof value !== 'undefined') next = value;
                if (prev === null && typeof defaultValue !== 'undefined') next = defaultValue;
                return next;
            });
        }, [value, defaultValue]);

        useEffect(() => {
            setInnerOptions((prev) => {
                if (isEqual(prev, options)) return prev;
                return options;
            });
        }, [options]);

        // update selected on outer value change
        useEffect(() => setSelectedOptions(findSelected(innerValues)), [findSelected, innerValues]);

        return (
            <div
                ref={rootRef}
                style={text}
                className={cn(
                    CssUiComponent.MUTLI_SELECT,
                    css.multiselect,
                    {
                        [css.small]: small,
                        [css.active]: isOpen,
                        [css.disabled]: disabled,
                        [css.error]: withError,
                        [css.top]: top
                    },
                    className
                )}
            >
                <SelectBox
                    active={isOpen}
                    customStyles={customStyles}
                    disabled={empty}
                    downshiftProps={getToggleButtonProps()}
                    error={withError}
                    id={id}
                    labelClassName={cn(labelClassName, css.label)}
                    ref={setReferenceRef}
                    searchable={searchable}
                    title={createTitle()}
                    top={top}
                    value={innerValues}
                    onClear={clear}
                    onClick={handleToggle}
                    preset={preset}
                    showArrow={options?.length > 1}
                />

                {maxWidth ? (
                    <Portal visible={isOpen}>{optionsOverlay}</Portal>
                ) : (
                    <Portal>{isOpen && optionsOverlay}</Portal>
                )}
            </div>
        );
    }
);
MultiSelect.displayName = 'MultiSelect';
