import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _states;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { assign, fromCallback, sendTo, setup } from 'xstate';
import { loadTimer, saveTimer } from "./lib";
import { SurveyState } from "./states";
export var TimerState;

(function (TimerState) {
  TimerState["RUNNING"] = " running";
  TimerState["EXPIRED"] = "expired";
  TimerState["ERROR"] = "error";
})(TimerState || (TimerState = {}));

export var initialTimerContext = {
  name: 'timer_machine',
  uniqueId: '',
  duration: null,
  interval: 1000,
  elapsed: 0,
  timeAgo: 0
};

var timerExpired = function timerExpired(_ref) {
  var _ref$context = _ref.context,
      duration = _ref$context.duration,
      elapsed = _ref$context.elapsed;
  return elapsed >= duration * 1000 || duration === null || duration <= 0;
};

export var TimerMachine = setup({
  types: {
    context: {},
    events: {},
    input: {}
  },
  actors: {
    ticker: fromCallback(function (_ref2) {
      var input = _ref2.input,
          sendBack = _ref2.sendBack;
      var interval = setInterval(function () {
        sendBack({
          type: 'TICK'
        });
      }, input.interval);
      return function () {
        clearInterval(interval);
      };
    })
  }
}).createMachine({
  initial: TimerState.RUNNING,
  schema: {
    context: {},
    events: {}
  },
  context: function context(_ref3) {
    var input = _ref3.input;
    var savedValue = loadTimer(input.uniqueId);
    return _objectSpread(_objectSpread({}, initialTimerContext), {}, {
      uniqueId: input.uniqueId,
      elapsed: savedValue ? savedValue : initialTimerContext.elapsed,
      duration: input.duration,
      parentRef: input.parentRef
    });
  },
  states: (_states = {}, _defineProperty(_states, TimerState.RUNNING, {
    invoke: {
      src: 'ticker',
      input: function input(_ref4) {
        var context = _ref4.context;
        return {
          interval: context.interval
        };
      },
      onError: {
        target: SurveyState.error
      }
    },
    on: {
      TICK: [{
        target: TimerState.EXPIRED,
        guard: timerExpired,
        actions: assign({
          timeAgo: function timeAgo(_ref5) {
            var context = _ref5.context;
            return context.elapsed;
          },
          stopReason: 'expired'
        })
      }, {
        actions: [assign(function (_ref6) {
          var context = _ref6.context;
          saveTimer(context.uniqueId, context.elapsed + context.interval);
          return {
            elapsed: context.elapsed + context.interval
          };
        })]
      }],
      STOP: {
        target: TimerState.EXPIRED,
        actions: assign({
          timeAgo: function timeAgo(_ref7) {
            var context = _ref7.context;
            return context.elapsed;
          },
          stopReason: 'next-bundle'
        })
      }
    }
  }), _defineProperty(_states, TimerState.EXPIRED, {
    type: 'final',
    entry: [sendTo(function (_ref8) {
      var context = _ref8.context;
      return context.parentRef;
    }, function (_ref9) {
      var stopReason = _ref9.context.stopReason;
      return {
        type: 'TIMER_EXPIRED',
        reason: stopReason
      };
    })]
  }), _defineProperty(_states, TimerState.ERROR, {// entry: assign((ctx, evt) => {
    //     // TODO HANDLE ERROR
    // })
  }), _states)
});