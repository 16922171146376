import cn from 'classnames';
import { forwardRef } from 'react';

import { mergeRefs } from '@webapp/common/lib/react';
import type { SelectCustomStylesConfig } from '@webapp/ui/lib/custom-styles';
import { ArrowDown, Search } from '@webapp/ui/lib/icons';

import type { TPreset } from '../index';

import css from './select-box.css';

export const SelectBox = forwardRef<
    HTMLDivElement,
    {
        id: string;
        value: any;
        title: ReactNode;
        onClick: () => void;
        onClear: (e) => void;
        labelClassName: string;
        disabled: boolean;
        searchable: boolean;
        customStyles: SelectCustomStylesConfig;
        error: boolean;
        active: boolean;
        top: boolean;
        downshiftProps: any;
        preset?: TPreset;
        showArrow: boolean;
    }
>(
    (
        {
            active,
            customStyles: { arrow, label, root },
            disabled,
            downshiftProps,
            error,
            id,
            labelClassName,
            onClick,
            searchable,
            title,
            top,
            value,
            preset,
            showArrow
        },
        ref
    ) => {
        const clearable = searchable && value.length > 0;

        return (
            <div
                id={id}
                style={root}
                tabIndex={0}
                className={cn(
                    css.label,
                    {
                        [css.placeholder]: value === null || value === undefined || value === '',
                        [css.disabled]: disabled,
                        [css.error]: error,
                        [css.clearable]: clearable,
                        [css.active]: active,
                        [css.top]: top
                    },
                    css[`label_preset_${preset}`],
                    labelClassName
                )}
                {...downshiftProps}
                onClick={onClick}
                ref={mergeRefs([downshiftProps.ref, ref])}
            >
                {showArrow && <ArrowDown className={css.arrow} style={arrow} />}
                <div className={cn(css.title, searchable && css.searchable)} style={label}>
                    {title}
                </div>
                {searchable && active && (
                    <div className={css.search}>
                        <Search />
                    </div>
                )}
            </div>
        );
    }
);

SelectBox.displayName = 'SelectBox';
