import cloneDeep from 'lodash/cloneDeep';
import memoize from 'lodash/memoize';

import type { DateTimeType, TimeFormat } from '@webapp/common/lib/date';
import { currentYear } from '@webapp/common/lib/date';
import { getColor } from '@webapp/common/lib/ui';
import { tempId } from '@webapp/common/lib/utils';
import { DropdownType, QuestionType } from '@webapp/common/resources/survey';
import type { AnswerType } from '@webapp/common/resources/survey';
import type { SmilePackName } from '@webapp/ui/lib/smile-image';

import { mapCondition } from 'resources/logic/map-logic';

import type { FillerQuestion } from './fillers';

export interface SmileType {
    name: string;
    value: string;
    smiles: Array<string>;
}

export const smileTypes: Readonly<Array<SmileType>> = [
    {
        name: '5 - смайлы',
        value: '5-smile',
        smiles: ['angry', 'bad', 'normal', 'good', 'perfect']
    },
    {
        name: '3 - смайлы',
        value: '3-smile',
        smiles: ['angry', 'normal', 'perfect']
    },
    {
        name: '2 - смайлы',
        value: '2-smile',
        smiles: ['angry', 'perfect']
    },
    {
        name: '2 - рука',
        value: '2-hand',
        smiles: ['not-approval', 'approval']
    }
];

export enum ResidenceType {
    COUNTRY = 'COUNTRY',
    REGION = 'REGION',
    CITY = 'CITY'
}

// TODO merge with to resources/survey
export const RESIDENCE_TYPE_LABEL = {
    [ResidenceType.COUNTRY]: 'Страна',
    [ResidenceType.REGION]: 'Регион',
    [ResidenceType.CITY]: 'Город'
};

export interface DateFormat {
    label: string;
    format: string;
}

export const EMPTY_PAGE_ID = -1;

export interface Question extends FillerQuestion {
    // TODO purge unused
    id: number;
    name: string;
    type: QuestionType;
    pageId: number;
    fetch?: boolean;
    expand?: boolean;
    typeMode?: boolean;
    previewMode?: boolean;
    isNew?: boolean;
    serial?: number;
    order?: number;
}

export type QuestionValue = string | number;

export interface QuestionValues {
    value: QuestionValue;
    value2?: QuestionValue;
    value3?: QuestionValue;
}

export const QuestionValuesKeys: Set<keyof QuestionValues> = new Set(['value', 'value2', 'value3']);

export interface QuestionRow extends QuestionValues {
    id: number;

    isNew?: boolean;

    type?: AnswerType;
    exception?: boolean;
    order?: number;

    fileId: string;
    filePath: string;
    width: number;
    height: number;
    mimeType: string;

    text?: string;
}

export interface QuestionCol {
    id: number;
    name: string;
    type?: AnswerType;
    exception?: boolean | null;
    answers?: Array<string>;
    order?: number;
    numeric_value?: number;
    questionId?: number;
    isNew?: boolean;
}

export interface NestedItem {
    id: number;
    name: string;
    parentId: number;
    level: number;
    answerId: number;
}

export enum FileUploadType {
    ANY = 'any',
    RESTRICTED = 'restricted'
}

export interface QuestionParams {
    name: boolean; // show name. TODO ?? purge

    help: boolean;
    helpText: string;

    required: boolean;

    comment: boolean;
    commentText: string;

    cantRate?: boolean;
    cantRateText?: string;

    commentRequired: boolean;

    timer: boolean;
    hours: number;
    minutes: number;
    seconds: number;

    randomOrder: boolean;

    excludeSelectedAnswer: boolean;

    textBlock: string;

    agreementLeftLabel: string;
    agreementRightLabel: string;
    agreementLink: string;
    agreementText: string;

    dateType: DateTimeType;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;

    minLength?: number;
    maxLength?: number;

    phoneFormatCountry: string;
    phoneFormatNumber: string;

    residence: ResidenceType;

    fileAmount: number;
    uploadButtonName: string;
    fileType: FileUploadType;
    fileLimitExt: Array<string>;

    answerType: 'any' | 'number';

    size: number;
    amount: number;
    color: string;

    npsLeftLabel: string;
    npsRightLabel: string;
    npsColors: Array<string>;

    distributeSurplus: boolean;
    distributeLabel: string;
    distributeScore: number;
    distributeUnit: string;

    dropdownListType: DropdownType;
    dropdownType: 'single' | 'multi';

    answers: Array<string>;

    imageColumns: number;
    questionColumns: number;
    imageTextPosition: 'under' | 'above';

    smileType: string;
    smilePack: SmilePackName;
    testScore?: number;
    totalScore?: boolean;
    matrixType: 'stars' | 'numbers';
    matrixNumbersColor: Array<string>;
    scaleType: 'number' | 'custom';
    scaleStartDia: number;
    scaleEndDia: number;
    scaleFormat: 'numbers' | 'numbers-with-borders' | 'slider';
    scaleColors: Array<string>;
    minAmount: number;
    maxAmount: number;
    csiFormat: string;
    chartType?: string;
    chartColor?: string;
}

export const initialQuestionParams: Readonly<QuestionParams> = {
    name: true,

    help: false,
    helpText: null,

    required: true,

    comment: false,
    commentText: '',
    commentRequired: false,

    timer: false,
    hours: 0,
    minutes: 1,
    seconds: 0,

    randomOrder: false,

    excludeSelectedAnswer: false,
    cantRateText: 'Не могу оценить',
    cantRate: false,

    textBlock: null,

    agreementLeftLabel: null,
    agreementRightLabel: null,
    agreementLink: null,
    agreementText: null,

    dateType: null,
    dateFormat: null,
    timeFormat: null,

    minLength: null,
    maxLength: null,
    phoneFormatCountry: null,
    phoneFormatNumber: null,

    residence: null,

    fileAmount: null,
    uploadButtonName: null,
    fileType: null,
    fileLimitExt: [],

    answerType: null,

    size: null,
    amount: null,
    color: null,

    npsLeftLabel: null,
    npsRightLabel: null,
    npsColors: [getColor('blue-nps')],

    distributeSurplus: null,
    distributeLabel: null,
    distributeScore: null,
    distributeUnit: null,

    dropdownListType: DropdownType.DIFFERENT,
    dropdownType: 'single',

    answers: [],

    imageColumns: 1,
    questionColumns: 1,
    imageTextPosition: 'under',

    smileType: null,
    smilePack: null,
    matrixType: 'stars',
    matrixNumbersColor: [getColor('blue-nps')],
    scaleType: 'number',
    scaleStartDia: 1,
    scaleEndDia: 5,
    scaleFormat: 'numbers',
    scaleColors: [getColor('blue-nps')],
    minAmount: 1,
    maxAmount: 10,
    csiFormat: 'numbers'
};

export const DateFormat: Array<DateFormat> = [
    {
        label: `25.11.${currentYear}`,
        format: 'dd.MM.yyyy'
    },
    {
        label: `11.25.${currentYear}`,
        format: 'MM.dd.yyyy'
    },
    {
        label: `${currentYear}.11.25`,
        format: 'yyyy.MM.dd'
    }
];

export const initialQuestion: Readonly<Question> = {
    fetch: false,
    typeMode: true,
    expand: true,
    previewMode: true,
    id: 0,
    name: '',
    pageId: null,
    type: QuestionType.ONE_OF_LIST,
    rows: [],
    cols: [],
    items: {},
    conditions: [],
    params: cloneDeep(initialQuestionParams)
};

export const initialCol: Readonly<QuestionCol> = {
    id: 0,
    name: '',
    type: null,
    exception: null,
    answers: []
};

export const initialRow: Readonly<QuestionRow> = {
    id: 0,

    value: '',
    value2: '',
    value3: '',

    type: null,
    exception: false,
    order: 0,

    fileId: null,
    filePath: null,
    width: 0,
    height: 0,
    mimeType: null,

    text: null
};

export const createSelectTypeQuestion = (pageId: number): Question => ({
    ...cloneDeep(initialQuestion),
    expand: true,
    previewMode: true,
    typeMode: true,
    isNew: true,
    id: tempId(),
    pageId,
    name: 'Выберите тип вопроса',
    type: QuestionType.DEFAULT,
    rows: [],
    cols: [],
    params: cloneDeep(initialQuestion.params)
});

export const createTypeSelectQuestion = (pageId): Partial<Question> => ({
    expand: true,
    previewMode: true,
    typeMode: true,
    id: tempId(),
    pageId: pageId || EMPTY_PAGE_ID,
    name: 'Выберите тип вопроса',
    type: QuestionType.DEFAULT,
    rows: [],
    cols: [],
    params: cloneDeep(initialQuestion.params)
});

const QUESTION_INTERCHANGE_GROUP1 = new Set([
    QuestionType.ONE_OF_LIST,
    QuestionType.FEW_OF_LIST,
    QuestionType.DROPDOWN_LIST,
    QuestionType.TEST_ONE_OF_LIST,
    QuestionType.TEST_FEW_OF_LIST,
    QuestionType.TEST_RANK
]);

const QUESTION_INTERCHANGE_GROUP2 = new Set([
    QuestionType.MATRIX_SINGLE_ANSWER,
    QuestionType.MATRIX_FEW_ANSWERS,
    QuestionType.MATRIX_TEXT_ANSWER,
    QuestionType.MATRIX_DROPDOWN_LIST,
    QuestionType.MATRIX_RATING,
    QuestionType.SEM_DIFF,
    QuestionType.CSI
]);

const QUESTION_INTERCHANGE_GROUP3 = new Set([
    QuestionType.LONG_TEXT,
    QuestionType.SHORT_TEXT,
    QuestionType.NUMBER,
    QuestionType.EMAIL,
    QuestionType.DATETIME,
    QuestionType.PHONE,
    QuestionType.GROUP_FREE_ANSWERS,
    QuestionType.NAME,
    QuestionType.TEST_TEXT
]);

const QUESTION_INTERCHANGE_GROUP4 = new Set([
    QuestionType.SCALE,
    QuestionType.NPS,
    QuestionType.STAR,
    QuestionType.SMILE,
    QuestionType.DISTRIBUTE_SCALE
]);

const QUESTION_INTERCHANGE_GROUP5 = new Set([QuestionType.SELECT_FEW_IMAGE, QuestionType.SELECT_ONE_IMAGE]);

const QUESTION_INTERCHANGE_GROUPS = [
    QUESTION_INTERCHANGE_GROUP1,
    QUESTION_INTERCHANGE_GROUP2,
    QUESTION_INTERCHANGE_GROUP3,
    QUESTION_INTERCHANGE_GROUP4,
    QUESTION_INTERCHANGE_GROUP5
];

export const getQuestionInterchange = memoize((type: QuestionType): Array<QuestionType> => {
    for (const g of QUESTION_INTERCHANGE_GROUPS) {
        if (g.has(type)) return Array.from(g);
    }

    return null;
});

export const mapRow = ({
    ANSWER,
    ANSWER_TEXT,
    EXCEPTION,
    FILE_ID,
    FILE_PATH,
    ID,
    IMAGE_HEIGHT,
    IMAGE_WIDTH,
    MIME_TYPE,
    ORDER,
    TYPE
}: any): QuestionRow => {
    const row: QuestionRow = {
        ...cloneDeep(initialRow),
        id: ID,
        value: ANSWER || '',
        type: TYPE,
        exception: !!EXCEPTION,
        fileId: FILE_ID,
        filePath: FILE_PATH || null,
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
        mimeType: MIME_TYPE,
        order: ORDER,
        text: ANSWER_TEXT
    };

    try {
        const value = JSON.parse(row.value as string);
        if (Array.isArray(value)) {
            row['value'] = value[0];
            row['value2'] = value[1];
            row['value3'] = value[2];
        }
    } catch (e) {}

    return row;
};

export const mapCol = ({ ANSWERS, EXCEPTION, ID, NAME, ORDER, TYPE }: any): QuestionCol => {
    const col: QuestionCol = {
        ...cloneDeep(initialCol),
        id: ID,
        name: NAME === null ? '' : NAME,
        type: TYPE,
        exception: Boolean(EXCEPTION),
        order: ORDER
    };

    try {
        col.answers = ANSWERS ? JSON.parse(ANSWERS) : []; // TODO fix backend
    } catch (e) {
        col.answers = [];
        console.error(e);
    }

    return col;
};

export const mapQuestion = (data: any): Question => {
    const {
        answers,
        groups,
        ID,
        logics,
        PAGE,
        parameters: { params },
        QUESTION,
        QUESTION_TYPE_ID
    } = data;
    const question: Question = {
        ...cloneDeep(initialQuestion),
        typeMode: false,
        id: ID,
        name: QUESTION === null ? '' : QUESTION,
        pageId: PAGE,
        type: QUESTION_TYPE_ID as QuestionType,
        rows: answers.map(mapRow),
        cols: groups.map(mapCol),
        conditions: logics.map((logic: any) => mapCondition(logic, data))
    };

    if (params) {
        try {
            question.params = Object.assign(cloneDeep(initialQuestion.params), JSON.parse(params));
        } catch (e) {
            console.error(e);
        }
    }

    return question;
};
