import dayjs from 'dayjs';
import isPlainObject from 'lodash/isPlainObject';
import { CheckPasswordStatus } from '@webapp/common/lib/api';
import { forceHeader, isPreview } from '@webapp/common/lib/const';
import { HeaderBackgroundType } from '@webapp/common/lib/ui';
import { UnescapeHtml } from '@webapp/common/lib/utils';
import { AnswerType, withClickReply } from '@webapp/common/resources/constants';
import { BundleFinishType, BundleType } from '@webapp/common/resources/entities';
import { WelcomePageType } from '@webapp/common/resources/entities/info';
import { calcSurveyProgress } from "./helpers/survey";
import { getQ, isOfflineMode } from "./lib";
import { SurveyState } from "./states";

var selfAnswersPresentAndNotEmpty = function selfAnswersPresentAndNotEmpty(q) {
  var selfAnswers = q.answersActors.filter(function (a) {
    return a.getSnapshot().context.type === AnswerType.SELF;
  });
  var withValue = selfAnswers.some(function (a) {
    var resp = a.getSnapshot().context.response.getSnapshot().context;
    var value = resp.value,
        extra = resp.extra;
    return value && !!extra;
  });
  return selfAnswers.length > 0 && withValue;
};

var anyNotEmptyAnswer = function anyNotEmptyAnswer(q) {
  var regularAnswers = q.answersActors.filter(function (a) {
    return a.getSnapshot().context.type !== AnswerType.SELF;
  });
  var withValue = regularAnswers.some(function (r) {
    return !!r.getSnapshot().context.response.getSnapshot().context.value;
  });
  var singleResponse = q.responseActor.getSnapshot().context.value;
  return regularAnswers.length > 0 && withValue || selfAnswersPresentAndNotEmpty(q) || !!singleResponse;
};

export var stateOrSubstate = function stateOrSubstate(state, targetState) {
  return state === targetState || isPlainObject(state) && Object.keys(state).includes(targetState);
};
export var selectWithPassword = function selectWithPassword(ctx) {
  var _ctx$info$params$main = ctx.info.params.main,
      individualPasswords = _ctx$info$params$main.individualPasswords,
      passwordAccess = _ctx$info$params$main.passwordAccess;
  return passwordAccess || individualPasswords;
};
export var selectAuthenticatedPwd = function selectAuthenticatedPwd(ctx) {
  var _ctx$tokenData, _ctx$tokenData2, _ctx$tokenData2$priva;

  return Boolean(typeof (ctx === null || ctx === void 0 ? void 0 : (_ctx$tokenData = ctx.tokenData) === null || _ctx$tokenData === void 0 ? void 0 : _ctx$tokenData.private) === 'object' && (ctx === null || ctx === void 0 ? void 0 : (_ctx$tokenData2 = ctx.tokenData) === null || _ctx$tokenData2 === void 0 ? void 0 : (_ctx$tokenData2$priva = _ctx$tokenData2.private) === null || _ctx$tokenData2$priva === void 0 ? void 0 : _ctx$tokenData2$priva.password) === 0);
};
export var selectAuthenticatedVk = function selectAuthenticatedVk(ctx) {
  var _ctx$tokenData3, _ctx$tokenData4, _ctx$tokenData4$priva;

  return Boolean(typeof (ctx === null || ctx === void 0 ? void 0 : (_ctx$tokenData3 = ctx.tokenData) === null || _ctx$tokenData3 === void 0 ? void 0 : _ctx$tokenData3.private) === 'object' && (ctx === null || ctx === void 0 ? void 0 : (_ctx$tokenData4 = ctx.tokenData) === null || _ctx$tokenData4 === void 0 ? void 0 : (_ctx$tokenData4$priva = _ctx$tokenData4.private) === null || _ctx$tokenData4$priva === void 0 ? void 0 : _ctx$tokenData4$priva.vkAuth) === 0);
};
export var selectAskPassword = function selectAskPassword(ctx) {
  var wp = selectWithPassword(ctx);
  var authPwd = selectAuthenticatedPwd(ctx);
  return wp && !authPwd;
};
export var selectAskVkAuth = function selectAskVkAuth(ctx) {
  var wp = selectWithPassword(ctx);
  var authPwd = selectAuthenticatedPwd(ctx);
  var authVk = selectAuthenticatedVk(ctx);
  var vkAuth = ctx.info.params.main.vkAuth;
  return vkAuth && (wp && authPwd || !wp && !authVk);
};
export var selectPwdStatus = function selectPwdStatus(ctx) {
  return ctx.pwdCheck;
};
export var selectCurrentBundle = function selectCurrentBundle(_ref) {
  var bundles = _ref.bundles,
      currentBundleIndex = _ref.currentBundleIndex;

  if (bundles.length === 0) {
    return null;
  }

  return bundles[currentBundleIndex];
};
export var selectIsFirstBundle = function selectIsFirstBundle(_ref2) {
  var currentBundleIndex = _ref2.currentBundleIndex;
  return currentBundleIndex === 0;
};
export var selectPwdError = function selectPwdError(_ref3) {
  var info = _ref3.info,
      pwdCheck = _ref3.pwdCheck;
  var error = null;
  var _info$params$main = info.params.main,
      passwordWasActivated = _info$params$main.passwordWasActivated,
      wrongPasswordLabel = _info$params$main.wrongPasswordLabel;

  switch (pwdCheck) {
    case CheckPasswordStatus.ERROR:
      error = wrongPasswordLabel;
      break;

    case CheckPasswordStatus.PASSWORD_ALREADY_USED:
      error = passwordWasActivated;
      break;

    default:
  }

  return error ? UnescapeHtml(error) : null;
};
export var selectDontShowNextButton = function selectDontShowNextButton(ctx) {
  var currentBundle = selectCurrentBundle(ctx);
  var clickReply = ctx.info.params.other.clickReply;
  return Boolean(currentBundle && clickReply && currentBundle.questions.every(function (q) {
    var ctx = q.getSnapshot().context;
    var commentEnabled = ctx.params.comment,
        type = ctx.type;
    var withNotEmpty = anyNotEmptyAnswer(ctx);
    var hideByEmptyAnswer = !withNotEmpty && !commentEnabled;
    return withClickReply(type) && hideByEmptyAnswer;
  }));
};
export var selectNextBundle = function selectNextBundle(_ref4) {
  var bundles = _ref4.bundles,
      currentBundleIndex = _ref4.currentBundleIndex;

  if (bundles.length === 0 || bundles.length === currentBundleIndex + 1) {
    return null;
  }

  return bundles[currentBundleIndex + 1];
};
export var selectNavImpossible = function selectNavImpossible(ctx, state) {
  var currentBundle = selectCurrentBundle(ctx);
  return Boolean(!stateOrSubstate(state, SurveyState.bundles) || !currentBundle || currentBundle.type !== BundleType.QUESTIONS);
};
export var selectNextButtonVisible = function selectNextButtonVisible(ctx, state) {
  var canNot = selectNavImpossible(ctx, state);
  var nextBundle = selectNextBundle(ctx);

  if (canNot) {
    return false;
  }

  return nextBundle && nextBundle.type === BundleType.QUESTIONS;
};
export var selectPrevButtonVisible = function selectPrevButtonVisible(ctx, state) {
  var canNot = selectNavImpossible(ctx, state);
  var prevBundle = selectPrevBundle(ctx);

  if (canNot) {
    return false;
  }

  return !!prevBundle;
};
export var selectShowNextButton = function selectShowNextButton(ctx, state) {
  var dontShowNextButton = selectDontShowNextButton(ctx);
  var nextButtonVisible = selectNextButtonVisible(ctx, state);
  return nextButtonVisible && !dontShowNextButton;
};
export var selectShowPrevButton = function selectShowPrevButton(ctx, state) {
  var prevButtonVisible = selectPrevButtonVisible(ctx, state);
  var backToQuestion = ctx.info.params.other.backToQuestion;
  return prevButtonVisible && backToQuestion;
};
export var selectSendButtonVisible = function selectSendButtonVisible(ctx, state) {
  var currentBundle = selectCurrentBundle(ctx);
  var nextBundle = selectNextBundle(ctx);

  if (!stateOrSubstate(state, SurveyState.bundles) || !currentBundle || currentBundle.type !== BundleType.QUESTIONS) {
    return false;
  }

  return !nextBundle || nextBundle.type !== BundleType.QUESTIONS;
};
export var selectShowSendButton = function selectShowSendButton(ctx, state) {
  var sendButtonVisible = selectSendButtonVisible(ctx, state);
  var dontShowNextButton = selectDontShowNextButton(ctx);
  return sendButtonVisible && !dontShowNextButton;
};
export var selectPrevBundle = function selectPrevBundle(ctx) {
  var bundles = ctx.bundles,
      currentBundleIndex = ctx.currentBundleIndex;
  var isFirstPage = selectIsFirstBundle(ctx);

  if (bundles.length === 0 || isFirstPage) {
    return null;
  }

  return bundles[currentBundleIndex - 1];
};
export var selectShowIndicator = function selectShowIndicator(_ref5, state) {
  var info = _ref5.info,
      timerRef = _ref5.timerRef;
  var indicator = info.params.other.indicator;
  return Boolean((indicator || timerRef) && state !== SurveyState.finished && state !== SurveyState.testResults);
};
export var selectShowHeader = function selectShowHeader(ctx) {
  var isFirstPage = selectIsFirstBundle(ctx);
  var _ctx$info$design$bran = ctx.info.design.brand,
      headerAllPage = _ctx$info$design$bran.headerAllPage,
      headerType = _ctx$info$design$bran.headerType;
  return Boolean(headerType !== HeaderBackgroundType.NOT_HEAD && (!isPreview || forceHeader) && (headerAllPage || isFirstPage));
};
export var selectIsStartPage = function selectIsStartPage(_, state) {
  return state === SurveyState.welcomePage;
};
export var selectWelcomePageText = function selectWelcomePageText(_ref6) {
  var info = _ref6.info;
  var welcomePageText = info.params.main.welcomePageText;
  return welcomePageText;
};
export var selectWelcomePage = function selectWelcomePage(_ref7) {
  var info = _ref7.info;
  var welcomePage = info.params.main.welcomePage;
  return welcomePage;
};
export var selectSaveAtFinish = function selectSaveAtFinish(ctx) {
  return !ctx.info.params.main.savingNotComplete;
};
export var selectShowWelcomeText = function selectShowWelcomeText(ctx, state) {
  var welcomePageText = selectWelcomePageText(ctx);
  var welcomePage = selectWelcomePage(ctx);
  var isFirstBundle = selectIsFirstBundle(ctx);
  var showToSurveyPage = isFirstBundle && state === SurveyState.bundles && welcomePage === WelcomePageType.TO_SURVEY_PAGE;
  var showToSinglePage = state === SurveyState.welcomePage && welcomePage === WelcomePageType.TO_SINGLE_PAGE;
  return Boolean(welcomePageText && (showToSurveyPage || showToSinglePage));
};
export var selectShowWelcomePage = function selectShowWelcomePage(ctx, state) {
  // const isStartPage = selectIsFirstBundle(ctx);
  var showWelcomeText = selectShowWelcomeText(ctx, state);
  return showWelcomeText;
};
export var selectFirstInvalidId = function selectFirstInvalidId(ctx) {
  var currentBundle = selectCurrentBundle(ctx);
  if (!currentBundle) return null;
  var firstInvalid = currentBundle.questions.find(function (questionRef) {
    var q = getQ(questionRef);
    return q.invalid || q.commentInvalid;
  });
  return firstInvalid ? firstInvalid.getSnapshot().context.id : null;
};
export var selectStartLoading = function selectStartLoading(_ref8) {
  var _ref8$loading = _ref8.loading,
      fetch = _ref8$loading.fetch,
      init = _ref8$loading.init,
      load = _ref8$loading.load;
  return fetch || load || init;
};
export var selectSendLoading = function selectSendLoading(_ref9) {
  var _ref9$loading = _ref9.loading,
      finish = _ref9$loading.finish,
      next = _ref9$loading.next;
  return next || finish;
};
export var selectNextLoading = function selectNextLoading(_ref10) {
  var next = _ref10.loading.next;
  return next;
};
export var selectSurveyProgress = function selectSurveyProgress(_ref11) {
  var questions = _ref11.questions;
  return calcSurveyProgress(questions);
};
export var selectInvalidQuestionsQuantity = function selectInvalidQuestionsQuantity(_ref12) {
  var questions = _ref12.questions;
  return questions.reduce(function (acc, questionRef) {
    return acc + (questionRef.getSnapshot().context.invalid ? 1 : 0);
  }, 0);
};
export var selectTimerRef = function selectTimerRef(_ref13) {
  var timerRef = _ref13.timerRef;
  return timerRef;
};
export var selectTimeAgo = function selectTimeAgo(_ref14) {
  var info = _ref14.info,
      timerRef = _ref14.timerRef;
  var now = dayjs();
  var _info$params$main2 = info.params.main,
      timerHours = _info$params$main2.timerHours,
      timerMinutes = _info$params$main2.timerMinutes,
      timerSeconds = _info$params$main2.timerSeconds;
  var allTime = now.add(timerHours, 'h').add(timerMinutes, 'm').add(timerSeconds, 's');

  if (!timerRef) {
    // TODO strict check
    // fixes reading value if timer not set
    return dayjs.duration(0);
  }

  var endTime = now.add(timerRef.getSnapshot().context.duration * 1000 - timerRef.getSnapshot().context.elapsed, 'ms');
  return dayjs.duration(allTime.diff(endTime));
};
export var selectAffectedQuestions = function selectAffectedQuestions(_ref15) {
  var questions = _ref15.questions;
  return questions; // @ts-ignore

  var withLogicTriggered = questions.filter(function (_ref16) {
    var shownByLogic = _ref16.machine.context;
    return shownByLogic;
  }); // @ts-ignore

  return withLogicTriggered.length > 0 ? withLogicTriggered : questions;
};
export var selectCompletePageTextWithPromocodes = function selectCompletePageTextWithPromocodes(_ref17) {
  var completePageText = _ref17.info.params.main.completePageText,
      promocode = _ref17.promocode;
  return completePageText ? completePageText.replace(/{promocode}/gi, promocode) : null;
};
export var selectInfo = function selectInfo(_ref18) {
  var info = _ref18.info;
  return info;
};
export var selectFactPoints = function selectFactPoints(_ref19) {
  var factPoints = _ref19.factPoints;
  return factPoints;
};
export var selectSurveyPages = function selectSurveyPages(_ref20) {
  var pages = _ref20.pages;
  return pages;
};
export var selectShowCompletePage = function selectShowCompletePage(context, state) {
  var stopReason = context.stopReason;
  var currentBundle = selectCurrentBundle(context);

  if (stopReason === BundleFinishType.DISQUAL && currentBundle.type === BundleType.TEXT) {
    return false;
  }

  if (stopReason === BundleFinishType.TEXT) {
    return false;
  }

  return state === SurveyState.finished;
};
export var selectShowBundles = function selectShowBundles(ctx, st) {
  var currentBundle = selectCurrentBundle(ctx);
  var bundlesState = st === SurveyState.bundles || stateOrSubstate(st, SurveyState.bundles);
  var showWelcomeText = selectShowWelcomeText(ctx, st);
  var welcomePage = selectWelcomePage(ctx);
  var stopReason = ctx.stopReason;

  if (stopReason === BundleFinishType.DISQUAL && currentBundle.type === BundleType.TEXT) {
    return true;
  }

  if (stopReason === BundleFinishType.TEXT) {
    return true;
  }

  if (showWelcomeText && welcomePage === WelcomePageType.TO_SINGLE_PAGE) {
    return false;
  }

  return Boolean(bundlesState && currentBundle);
};
export var selectIsStart = function selectIsStart(_, st) {
  return st === SurveyState.welcomePage;
};
export var selectDomain = function selectDomain(_ref21) {
  var domain = _ref21.domain;
  return domain;
};
export var selectToken = function selectToken(_ref22) {
  var token = _ref22.token;
  return token;
};
export var selectScrollToQuestionId = function selectScrollToQuestionId(_ref23) {
  var scrollToQuestionId = _ref23.scrollToQuestionId;
  return scrollToQuestionId;
};
export var selectInitialLoading = function selectInitialLoading(_, state) {
  return state === SurveyState.idle;
};
export var selectShowNav = function selectShowNav(_, state) {
  return state !== SurveyState.testResults;
};
export var selectTestResult = function selectTestResult(_, state) {
  return state === SurveyState.testResults;
};
export var selectStatusMessage = function selectStatusMessage(ctx, state) {
  var _selectInfo = selectInfo(ctx),
      _selectInfo$params$ma = _selectInfo.params.main,
      disabledSurveyText = _selectInfo$params$ma.disabledSurveyText,
      surveyWasBlocked = _selectInfo$params$ma.surveyWasBlocked;

  return state === SurveyState.blocked ? surveyWasBlocked : disabledSurveyText;
};
export var selectErrorText = function selectErrorText(_ctx, _state) {
  return true ? 'Упс... Что-то пошло не так. Попробуйте обновить страницу.' : 'Упс... Что-то пошло не так. Попробуйте нажать ещё раз.';
};
export var selectSaveIncomplete = function selectSaveIncomplete(ctx) {
  var _selectInfo2 = selectInfo(ctx),
      _selectInfo2$params$m = _selectInfo2.params.main,
      savingNotComplete = _selectInfo2$params$m.savingNotComplete,
      timerForSurvey = _selectInfo2$params$m.timer,
      timerAfterQuestionId = _selectInfo2$params$m.timerOn;

  return (savingNotComplete || !!timerAfterQuestionId || !!timerForSurvey) && !isOfflineMode();
};